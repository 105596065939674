<template>
  <div id="toc">
    <div id="toc-content">
      <ul>
        <li v-for="li in items" :key="li.text">
          <b-link :href="'#' + li.id">{{ li.text }}</b-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toc",
  props: {
    items: Array
  }
}
</script>

<style scoped lang="scss">
#toc {
  display: inline-flex;
  padding: 1.5em;
  flex-direction: column;
  background-color: $grey-3;
  border: 1px solid transparent;
  border-radius: 25px;

  ul {
    margin: 0;
    padding-left: 1rem;
  }

  span {
    padding-bottom: 0.5rem;
    font-size: 20px;
    display: block;
  }

  li {
    padding-bottom: 0.25rem;

    a {
      margin-left: 0.5rem;
    }
  }
}
</style>
